import { BankConfig } from '../typings/types';

const config: BankConfig = {
  name: 'Djurslands Bank',
  bankId: 13,
  logoWidth: 180,
  color: '#005b67',
  googleAnalytics: {
    test: 'UA-53256967-13',
    production: 'UA-53256967-8'
  },
  forceOverride: false,
  backgroundGradient: ['#cae7ea', '#cae7ea'],
  backgroundImage: '13_bg.png',
  topBar: { background: '#005b67', color: '#cae7ea' },
  body: { headlineColor: '#005b67', bodyColor: '#005b67' }
};

export default config;
