import { mergeObjects } from '../app/common/utils';
import StandardForm from './form.standard';
import { FormConfig } from '../typings/types';

const customizations: FormConfig = {
  ...StandardForm,
  form: {
    ...StandardForm.form,
    questions: {
      purposeOfCustomerRelationship: {
        placeholder: 'Hvad ønsker du at bruge banken til?'
      },
      cashDepositComment: {
        placeholder:
          'Angiv hvor pengene kommer fra. Er det kontante gaver eller har du f.eks. mange indbetalinger fra loppemarkeder og lign.'
      },
      cashWithdrawalComment: {
        placeholder:
          'Hvis du hæver kontanter i stedet for at bruge kort eller overførsler. Hæver du kontanter til daglig brug, til ferierejser eller andre specifikke formål?'
      },
      foreignReceiveComment: {
        placeholder:
          'Hvad er årsagen til overførslerne – er der f.eks. tale om tilbagevendende overførsler fra én eller flere udenlandske afsendere.'
      },
      foreignTransferComment: {
        placeholder:
          'Hvad er årsagen til overførslerne – er der f.eks. tale om tilbagevendende overførsler til én eller flere udenlandske modtagere.'
      }
    }
  }
};

export default mergeObjects(StandardForm, customizations);
